import React from 'react';

import {Route, Routes} from 'react-router-dom';
import Home from './Components/home';
import Register from './Components/Register';
import NavBar from './Components/Navbar';
import Contents from './Components/Contents';
import Join from './Components/Join';
import Post from './Components/Post';
import Blog from './Components/Blog';
import Portofolio from './Components/Portofolio';
import Counter from '../src/Components/features/counter/Counter';

function App() {
  return (
    <div className="App">

    <NavBar/>
    
      <Routes>
        <Route path="/home" element={<Home/>}/>
        <Route path="/portofolio" element={<Portofolio/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<Join/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/contents" element={<Contents/>}/>
        <Route path="/post" element={<Post/>}/>
        <Route path="/auth" element={<Blog/>}/>
        <Route path="/counter" element={<Counter/>}/>
      </Routes>
      
     
    </div>
  );
}

export default App;
