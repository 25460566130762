
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import '../CSS/contents.css';
import parse from 'html-react-parser';



function Contents() {
   const [content, setContent] = useState<any>([]);
  function setElapsedTime(dateDB: number): string{
    let date = (Date.now()- dateDB)/1000;
    let timePassed = "";
    if(date < 60){
      timePassed = Math.floor(date)+" seconds ago";
    }else if(date < 3600){
      timePassed = Math.floor(date/ 60)+" munites ago";
    }else if(date < 86400){
      timePassed = Math.floor(date / 3600)+" hours ago";
    }else if(date < 604800){
      timePassed = Math.floor(date / 86400)+" days ago";
    }else if(date < 2629744){
      timePassed = Math.floor(date / 604800)+" weeks ago";
    }else if(date < 31556926){
      timePassed = Math.floor(date / 2629744)+" months ago";
    }else{
      timePassed = Math.floor(date / 31556926)+" years ago";
    }
    return timePassed;
  }
  useEffect(()=>{
    //https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev || http://localhost:5005/contents
    axios.defaults.withCredentials = true;
    axios.get("https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/contents/allcontents")
    .then(res=>{
        console.log(res.status);
        setContent(res.data.post);
    }).catch(err=>{setContent(err.data.message)})
  }, []);

  return (
    <div className='content'>
    
    
  
    <div className='content-container'>
      


    <main className='content-grid'>

     
     
      {(content.length < 1) && <h2>Programming contents will be added soon. <br></br><br></br>
      In the mean time free to share any ideas or post questions</h2>}<br></br>
      <br></br><h5>{content.map((i:any)=>{
        return (<div key = {i.username + ""+i.date}>
        <div className='post-contents' >
        <article className='content-grids'>
          {/* <h1>{setElapsedTime(Math.floor((i.date)))}</h1> */}
        <div className='username'>{i.username}</div>
        <div className='date'>{setElapsedTime(Math.floor((i.date)))}</div>
        <div className='post-text'>{parse(i.post)}</div>
        </article>
        </div>
        <div className='replayButton'>
        {/* <div><button  type='submit' id='like'>like</button></div>
        <div><button  type='submit'id='dislike'>dislike</button></div> */}
        {/* <div><button  type='submit' id='comment'>reply</button></div> */}
        </div>
        </div>)
      })}</h5>
      
      </main>
    
    </div>
  </div>
  )
}

export default Contents