import { configureStore } from "@reduxjs/toolkit";
import { counterActions } from "../features/counter/counterSlice";

const store = configureStore({
    reducer: {
        counter: counterActions
    }
});

export default store;
//added recently
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
