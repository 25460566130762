import React from 'react'
import profile from '../images/profile.jpg';
import '../CSS/porto.css';

function Portofolio() {
  return (<div className='conatinAllPorto'>
  
  <div className='portoContainer'>

    <h2>Portofolio</h2><br></br>
    <div className='checkDiv'>
        
        <div className='portoDiscription'>
        <div className='portofolioPhoto' ><img src={profile} alt='aws53' id='portoImage' /></div>
                <div className='portPara'>

                <ul>
                <h4>My name is Melkieneh Worku(Software Engineer).</h4>
                <h6>I started my software engineering Journey in 2018. Before I started my software
                    career I was a horticulturalist which is a little different from my current career.
                    Once I started a software engineering career, I fall in love designing and developing software. 
                    I have been in this career path for a while and
                    I have plenty of experiences in full stack development, data analysis and algorithms.<br/><br/>
                    Through this time, I have learned different technologies such as, Java, Spring Boot, AWS,
                    JavaScript, React, Angular and more. These Knowledge were very essential to reach
                    the level where I am right now.<br/><br/>
                    When I started in this field, I was assuming to learn the whole technologies in short pariod of time, but I realized
                    that was not possible to grasp all, so I decide to learn through time. While I am learning I like to
                    share my knowledge as well which may help someone who is looking to learn the technologies. So, I decided to create this website
                    to share and encourage others to do the same. 
                </h6>
                </ul>
                <div><h4>Links</h4>
            <h6>LinkedIn: <a href='https://www.linkedin.com/in/melkieneh-worku-ba8b26134/'>My linkedin<br/></a></h6>
            
            <h6>Github: <a href='https://github.com/yibeltal093'>My github</a></h6>
            
            </div>
                </div>

                
            </div>
        </div>
    </div>
  </div>
    
  )
}

export default Portofolio;