import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increament, decreament, increamentByAmount } from './counterSlice';



function Counter() {
    const count = useSelector((state: any)=>state.counter.value);
    const dispatch = useDispatch();
  return (

    <section >
        <div>
        <div style={{paddingTop: '100px',fontSize: "60px", color: 'white'}}>
            <button onClick={()=>dispatch(increament())}>Increament</button>
            <button onClick={()=>dispatch(decreament())}>Decreament</button>
            <button onClick={()=>dispatch(increamentByAmount(10))}>add10</button>
        </div>
        <p style={{fontSize: "80px", color: 'white'}}>Counter: {count}</p>
        </div>
    </section>
  )
}

export default Counter