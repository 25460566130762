import axios from 'axios';
import React, {  useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../CSS/post.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function Post() {
    let [content, setContent] = useState({post: ""});
    const [message, setMessage] = useState('');
  
    axios.defaults.withCredentials = true;
    const navigate = useNavigate();
    async function axiosPostData(){
      //const URL = "https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/include-credentials/contents/create";
      const requestBody = {post: content.post};
      const URL = "https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/contents/create";
      if(content.post.trim() === ''){
        setMessage('You can\'t post empty content');
        console.log('Post is empty');
        return;
      }else{
        axios.post(URL, requestBody)
        .then(response=>{
          if(response.status === 201){
            console.log('running inside');
            navigate('/contents');
            window.location.reload();
            setMessage( response.data.message);
            setContent(response.data.message);
          }
          
        }).catch(error=>{
          if(error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403){
                setMessage(error.response.data.message);
          }else{
              setMessage('Sorry the backend server is down');
          }
        })
        if(sessionStorage.getItem('token')){
          console.log('Post is sucessful');
          navigate('/contents');
        }else{
          console.log('unable to post at this time');
        }
      }
    }
    function handleChange(content: any, targetValue: any) {
      if(targetValue === ''){
        setMessage('post is impty');
      }else{
        setContent({...content, post: targetValue});
        //setMessage('posted');
      }
  
    }
    async function handleSubmit(event: any){
      event.preventDefault();
      
      const postText = await axiosPostData();
      return postText;
    }

    let toolbarOptions =[
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
 
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }], ['link', 'image'],                        // text direction
    
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
    
      ['clean']                                         // remove formatting button
    ];
    const module = {
      toolbar: toolbarOptions,
    }

      return (
        <div className='post-content'>
        
        
      
        <div className='post-container'>

        
          <h4 className='postMessage'>{message&& message}</h4>
        
            <form onSubmit={handleSubmit}>
            <ReactQuill className='reactQuill' modules={module} theme='snow' onChange={value=>{handleChange(content.post, value)}} />
        
            <div className='postButton'><button id='textButton' type='submit'>Post</button></div>

            </form>
            
        </div>

       
      
  </div>
      )
}

export default Post