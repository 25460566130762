import axios from 'axios';
//import Cookies from 'js-cookie';
import React, { useState } from 'react';
import '../CSS/login.css';
//import { useNavigate } from 'react-router-dom';

function Login(){
    let [state, setState] = useState({
        username: "",
        password: ""
    });
     const [message, setMessage] = useState(''); //It can be string as well
    
    async function handleSubmit(event: any){
        event.preventDefault();

        console.log(state);

        const response = await axiosPostData();
        //console.log(response);

        return response;
        
        // const fetchPost = await fetchPostData();
        // console.log(fetchPost);
    }
    function usernameChange(event: any){
        if(event.target.value === ''){
            console.log('Username cannot be empty')
            setMessage("Username cannot be empty")
        }
        setState({...state, username: event.target.value});
    }
    function passwordChange(event: any){
        if(event.target.value === ''){
            console.log('Password cannot be empty')
            setMessage("Password cannot be empty")
        }
        setState({...state, password: event.target.value});
    }

    //const navigate = useNavigate();
    axios.defaults.withCredentials = true; //This sets the token in front end
    async function axiosPostData() {
        //const URL = "https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/login";
        const URL = "https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/login";
        //const requestBody = {username: state.username, password: state.password};
        
        axios.post(URL, state).then(response =>{
           console.log(response.status); 
            if(response.status === 200){
                setMessage(response.data.message);
                //setTimeout(()=>navigate('/auth'), 600);
                window.location.href='/auth';
                
            }
        }).catch(error =>{
            console.log(error.response.status);
            if(error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 403){
                    console.log(error.response.status);
                    setMessage(error.response.data.message);
            }else{
                setMessage('Sorry the backend server is down');
            }
        })
    }

    
    return (
        
            <div className='login'>
                <h5>Please Enter your username and password to login</h5>


                
                <form onSubmit={handleSubmit}>
 
                    <input type='email' placeholder='username' onChange={usernameChange}></input>
                    
                  <br></br>
                    <input type='password' placeholder='password' onChange={passwordChange}></input>
                    <br></br>
                    <button id = 'log' type='submit' >Login</button>
                </form>
                <div className='errorMessage'>{(message !== '') && <h3>{message}</h3>}</div>
              
            </div>
            
        
    );
}

export default Login;