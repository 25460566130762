import React, { useEffect, useState } from 'react'
//import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import '../CSS/navbar.css';
import logo from '../images/homelogo1.png';
import axios from 'axios';

function NavBar() {
    const [auth, setAuth] = useState(false);
    // // const [username, setName] = useState('');
    const [message, setMessage] = useState('')

    axios.defaults.withCredentials = true;
    useEffect(()=>{
        axios.get("https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/auth")
        .then(res=>{
            if(res.status === 200){
                setAuth(true);
                console.log(res.status);
                console.log(res.data.message);
                console.log(`Auth is: ${auth}`);
                setMessage(res.data.message);
                
            }else if(res.status === 400 
                || res.status === 401 
                || res.status === 403){
                
                console.log("Token is not yet created");
                console.log(`Auth is: ${auth}`);
                console.log(res.status);
                setAuth(false);
                setMessage(res.data.message);
                
            }
            
        })
        .catch(error=>{
            setAuth(false);
            setMessage(error.response.data.message);
            console.log("User is not authonticated");
            console.log(error.response.data.message);
        })
    }, [auth]);

    
    const navigate = useNavigate();
    const handleLogout = ()=>{
        axios.get("https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/logout")
        .then(res=>{
            if(res.status === 200){
                //sessionStorage.removeItem('token');
                console.log(res.data.username);
                setTimeout(()=>navigate('/home'), 600);
                //window.location.reload();
            }
        }).catch(err=>{
            console.log(err);
            setMessage(err.data.message);
        })
    }

    
    //let counter = useSelector((state: any)=>state.counter.value);
  return (
    <>
 

    <nav className="navbar navbar-expand-md bg-dark navbar-dark">
        <Link className="navbar-brand" to="/"><span><img src={logo} alt="Ige" id="logoImage"/></span></Link>
        
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <Link className="navbar-brand" to="/home">Home</Link>
            <ul className="navbar-nav">
            <li className="nav-item">
                <Link className="nav-link" reloadDocument to="/contents">Contents</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" reloadDocument to="/post">Post</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/portofolio">About me</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" reloadDocument to="/auth">Blog</Link>
            </li>  
            {/* <li className="nav-item">
                <Link className="nav-link" reloadDocument to="/counter">Counter</Link>
            </li>       */}
            </ul>
        
             
        </div>
        
        <div className="justify-content-end" id="navbarLogin">
            <ul className="navbar-nav mr-auto">
                <li className='nav-item'>
                {!auth && <Link className="nav-link" to="/login" id='loginReg'>Register/Login</Link>}
                {auth && <Link className="nav-link" reloadDocument to="/login" onClick={handleLogout}>Logout  {message}</Link>}
                </li>
                
            </ul> 
        </div>



        
        </nav>

    </>
  )
}

export default NavBar