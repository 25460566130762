import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CounterSlice {
    value: number;
}

const initialState: CounterSlice = {
    value: 0,
};


const counterSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        increament: (state)=>{
            state.value += 1;
        },
        decreament: (state)=>{
            state.value -= 1;
        },

        increamentByAmount: (state, action: PayloadAction<number>)=>{
            state.value += action.payload;
        },
    },
    
});

//export const counterActions = counterSlice.actions;
export const counterActions = counterSlice.reducer;
export const {increament, decreament, increamentByAmount} = counterSlice.actions;

//Sclices are where you define the initial state and the actions you can apply
//to that state