import React, { useState } from 'react'
import Modal from './modal';
import LoginForm from './Login';
import RegisterForm from './Register';
import '../CSS/join.css';
function Join() {
     let [modalOpen] = useState<boolean>(true);
     let [prevUser, setPervUser] = useState<boolean>(true);


  return (
    <div className='join'>
        
        {modalOpen &&
            <Modal>
                  <div className='modalForm'>
                      {prevUser && <LoginForm></LoginForm>}
                      {!prevUser && <RegisterForm></RegisterForm>}
                  
                </div>

              <div className='joinButton'>
                <button id = 'login' type='button' onClick={() => { setPervUser(true); } } >login</button>
                <button id = 'signup' type='button' onClick={() => { setPervUser(false); } }>sign up</button>
              </div>
            </Modal>
        }
    </div>
  )
}

export default Join