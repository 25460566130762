import axios from 'axios';
import '../CSS/blog-content.css';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';

function Blog() {
    const [auth, setAuth] = useState(false);
    //const navigate = useNavigate();
    axios.defaults.withCredentials = true;
    useEffect(()=>{
        
      axios.get("https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/auth")
      .then(response=>{

        console.log(response.status)
        if(response.status === 200){
          setAuth(true);
        }
      }).catch(error=>{
        console.log(error.message);
        setAuth(false);
      })
    }, [auth]);
    
  return (
    // <div className='blogContainer'>
    //     {/* <Link reloadDocument to='/auth'></Link> */}
    //   <div className='blog-about'>

        


    //     {auth&& <div className='blog-content'>
    //     <h1>Hello there, Welcome to the page</h1>

        

    //     <h2>New technologies and latest news will be posted here</h2>
    //     <h2><br></br>There is no new blog currently please check out later</h2>
    //     </div>}
    //     {!auth&& <div className='blog-content'><h1>Please sign up to see all contents!</h1>
    //         <div className='joinPage'>
    //         <ul>
    //             <li className='nav-item'>
    //             <Link to="/login"><button>Join</button></Link>
    //             </li>

    //         </ul>
    //         </div>
    //     </div>}
        
      
    // </div>
    // </div>




<div className='conatinAllBlog'>
  
  <div className='portoContainer'>


    <div className='checkBlog'>
        <div className='blogContainer'>
          
                <h1>Hello</h1>

                {auth&& <div className='blog-content'>
                  <h1>Hello there, Welcome to the page</h1>
                  <h2>New technologies and latest news will be posted here</h2>
                  <h2><br></br>There is no new blog currently please check out later</h2>
                </div>}

                {!auth&& <div className='blog-content'><h1>Please sign up to see all contents!</h1>
            <div className='joinPage'>
            <ul>
                <li className='nav-item'>
                <Link to="/login"><button>Join</button></Link>
                </li>

            </ul>
            </div>
        </div>}

            </div>
        </div>
    </div>
  </div>
    
  )
}

export default Blog