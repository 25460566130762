import React from 'react'
import '../CSS/home.css';
import {Link} from 'react-router-dom';
import logo53 from '../images/contentImages/awsServices.png';
import reactLogo from '../images/contentImages/react.png';
import angularLogo from '../images/contentImages/anglular.png';
import javaLogo from '../images/contentImages/Java-Logo.png';
import javaScriptLogo from '../images/contentImages/JavaScript-Logo.png';

import cPlusPlus from '../images/contentImages/c++.png';
import python from '../images/contentImages/python.jpg';
import node from '../images/contentImages/Node.png';

import dynamo from '../images/contentImages/DynamoDB.png';
import mongoDB from '../images/contentImages/mongodb.png';
import mySQL from '../images/contentImages/MySQL-Logo.png';
import postgres from '../images/contentImages/postgresql.png';

import frontend from '../images/contentImages/frontend.png';
import dataStructure from '../images/algorithmImages/dataStructure.png';
import algorithms from '../images/algorithmImages/algorithms.png';
import axios from 'axios';
//import axios from 'axios';
//import axios from 'axios';


function Home() {
  
  axios.get("https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user")
  .then(response=>{
    console.log(response.data.message)
    //window.location.reload();
  }).catch(error=>{
    console.log(error.respnse.message)
  })
  function refereshPage(){
    window.location.reload();
  }
  
  axios.defaults.withCredentials = true;

  return (
    
  
    <div className='home' onClick={refereshPage}>
      
      
      <div className='home-about'>

        


        <div className='homeTopic'>
        <br></br>
        <video autoPlay muted id='myVideo'> 
        <source src={require('../images/topBox.mp4')} type='video/mp4'/>
        </video>
          
        </div>
        </div>

        

        
      
    

    
    <main className='grid'>
      <article className='grids grid-span-2'>
      <h3>Latest Topics</h3>
          <ul>
            
            <li><h4>You will get information about latest technologies and updates</h4></li>
            <li><h4>Coding and different algorithms</h4></li>
            <li><h4>Common errors and possible solutions of current technologies</h4></li>

          </ul>
          <div className='joinParag'><h2><br></br>Always Welcome to join</h2></div>
          <div className='joinPage'>
          
            <ul>
              <li className='nav-item'>
                <Link to="/login"><button>Join</button></Link>
              </li>

            </ul>
          </div>
      </article>
      <article className='grids'>
      <h2 className="card-title">Full Stack</h2>
      <ul>
            <li>
              <img src={logo53} alt='aws53' id='cardImage' /><h3>AWS Cloud</h3>
            </li>

            <li>
              <img src={reactLogo} alt='aws53' id='cardImage' /><h3>Reactjs</h3>
            </li>

            <li>
              <img src={angularLogo} alt='aws53' id='cardImage' /><h3>Angular</h3>
            </li>

            <li>
              <img src={javaLogo} alt='aws53' id='cardImage' /><h3>Java</h3>
            </li>
            <li>
              <img src={javaScriptLogo} alt='aws53' id='cardImage' /><h3>JavaScript</h3>
            </li>
            <li>
              <img src={frontend} alt='aws53' id='cardImage' /><h3>Databases, CSS, HTML, etc</h3>
            </li>
          </ul>
      </article>
      <article className='grids'>
      <h2 className="card-title">Databases</h2>
          <ul>
          
          <li>
              <img src={postgres} alt='aws53' id='cardImage' /><h3>PostgreSQL</h3>
            </li>
            <li>
              <img src={mySQL} alt='aws53' id='cardImage' /><h3>MySQL</h3>
            </li>

            <li>
              <img src={mongoDB} alt='aws53' id='cardImage' /><h3>MongoDB</h3>
            </li>
            <li>
              <img src={dynamo} alt='aws53' id='cardImage' /><h3>DynamoDB</h3>
            </li>
            
          </ul>
      </article>
      <article className='grids'>
      <h2 className="card-title">Programming Languages</h2>
          <ul>
            <li>
              <img src={cPlusPlus} alt='aws53' id='cardImage' /><h3>C++</h3>
            </li>

            <li>
              <img src={python} alt='aws53' id='cardImage' /><h3>Python</h3>
            </li>

            <li>
              <img src={node} alt='aws53' id='cardImage' /><h3>Nodejs</h3>
            </li>

            <li>
              <img src={javaLogo} alt='aws53' id='cardImage' /><h3>Java</h3>
            </li>
            <li>
              <img src={javaScriptLogo} alt='aws53' id='cardImage' /><h3>JavaScript</h3>
            </li>
            <li>
              <img src={frontend} alt='aws53' id='cardImage' /><h3>Databases, CSS, HTML, etc</h3>
            </li>
          </ul>
      </article>
      <article className='grids'>
      <h2 className="card-title">Data Structures</h2>
          <ul>

            <li>
              <img src={dataStructure} alt='aws53' id='dataImage' /><h3>Data structers</h3>
            </li>

            
          </ul>

      </article>

      <article className='grids'>
      <h2 className="card-title">Algorithm</h2>
          <ul>
            <li>
              <img src={algorithms} alt='aws53' id='algoImage' /><h3>Algorithms</h3>
            </li>

          </ul>

      </article>

      {/* <article className='grids'>
     

      </article> */}
    </main>



    
    </div>
  
  )
}

export default Home