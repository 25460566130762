import React, {FunctionComponent, PropsWithChildren} from 'react'
import '../CSS/modal.css'

const modal:FunctionComponent<PropsWithChildren> = ({children})=>{
  return (
    
    <div className='modal-container'>
      
        <div className='medal'>
        <div className='modal-header'>
          <a className='btn' href='/home'>&times;</a>
        </div>
          
          {children? (<div className='modal-content'>
              {children}
          </div>):(
              <div className='modal-header'>
              <p>Username and password can't be empty</p>
              </div>
          )}
          {/* <div className='modal-footer'>
              <a className='btn' href='/home'>cancel</a>
          </div> */}
        </div>
    </div>
  )
}

export default modal