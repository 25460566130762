
import axios from 'axios';
//import { response } from 'express';
import React, { useState } from 'react';
import '../CSS/registration.css';
//import { useNavigate } from 'react-router-dom';


//const URL = ' https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/register';
function Register(){
    let [state, setState] = useState({
        username: "",
        password: "",
        confirmPassword: ""
    });
    const [message, setMessage] = useState('');


    //const navigate = useNavigate();
    axios.defaults.withCredentials = true;
    async function axiosPostData() {
        const URL = "https://g55ga8vgrf.execute-api.us-west-2.amazonaws.com/dev/user/register";
        const requestBody = {username: state.username, password: state.password,
             confirmPassword: state.confirmPassword};
        
        await axios.post(URL, requestBody)
        .then(response=>{
            console.log(response.status);
            if(response.status === 201){
                setMessage(response.data.message);
                //setTimeout(()=>navigate('/auth'), 3000);
                window.location.href='/auth';
            }
        })
        .catch(error =>{
            console.log(error.response.status);
            if(error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 403){
                    console.log(error.response.status);
                    setMessage(error.response.data.message);
            }else{
                setMessage('Sorry the backend server is down');
            }
        })
    }
    async function handleSubmit(event: any) {
        event.preventDefault();
        const response = await axiosPostData();
        return response;
    }
    function emailChange(event: any) {
        if(event.target.value === ''){
            setMessage('email can\'t be empty');
        }
        setState({...state, username: event.target.value})
    }

    function usernameChange(event: any) {
        if(event.target.value === ''){
            setMessage('Username can\'t be empty');
        }
        setState({...state, username: event.target.value})
    }

    function passwordChange(event: any){
        if(event.target.value === ''){
            setMessage('Password can\'t be empty');
        }
        setState({...state, password: event.target.value})
    }

    function confirmPassword(event: any){
        if(event.target.value === ''){
            setMessage('confirm password can\'t be empty');
        }
        setState({...state, confirmPassword: event.target.value})
    }

    return (
        
            <div className='register'>
                <h5>Please Enter your username and password to register</h5>
                
                <form onSubmit={handleSubmit}>
 
                    <input type='email' placeholder='email' onChange={emailChange}></input>
                    
                  <br></br>
                  <input type='text' placeholder='username' onChange={usernameChange}></input>
                    
                  <br></br>
                    <input type='password' placeholder='password' onChange={passwordChange}></input>
                    <br></br>
                    <input type='password' placeholder='confirm password' onChange={confirmPassword}></input>
                    <br></br>
                    <button id = 'reg' type='submit'>Register</button>
                </form>
                <div className='errorMessage'>{(message !== '') && <h3>{message}</h3>}</div>
            </div>
            
        
    );
}

export default Register